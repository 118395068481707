import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  lang: number;
  apiURL: string = "/api/";

  constructor(private http?: HttpClient) {}

  async getLanguage() {
    await this.http.get<any>(this.apiURL + "get-language/").subscribe((any) => {
      if (any && any.length > 0) {
        this.lang = any[0].language;
      }
    });
    if (this.lang == 0 || this.lang == 1) {
      return this.lang;
    } else {
      return (this.lang = 0);
    }
  }
}
