import { Component, OnInit } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "ngx-language-switcher",
  styleUrls: ["./language-switcher.component.scss"],
  template: `
    <label class="switch-label">
      <span class="first" [ngClass]="{ active: checkVal == 0 }"> EN </span>
      <div class="switch">
        <!--        (click) = "OnLanguageChange($event)"-->
        <input
          type="checkbox"
          [checked]="isChecked"
          (click)="OnLanguageChange($event)"
        />
        <span class="slider"></span>
      </div>
      <span class="second" [ngClass]="{ active: checkVal == 1 }">AR</span>
    </label>
  `,
})
export class LanguageSwitcherComponent implements OnInit {
  apiURL: string = "/api/";
  checkbox: number = 0;
  isChecked = false;
  checkVal: any;
  exportLanguage: number;

  status: number;
  language: any = {};

  constructor(private http: HttpClient, private apiService: ApiService) {}

  ngOnInit() {
    this.http.get<any>(this.apiURL + "get-language/").subscribe((any) => {
      if (any && any.length > 0) {
        this.checkVal = any[0].language;
        this.isChecked = this.checkVal ? true : false;
      }
    });
  }

  services() {
    this.exportLanguage = this.checkVal;
  }

  OnLanguageChange(event) {
    this.language.lang = event.target.checked == true ? 1 : 0;
    this.apiService.updateLanguage(this.language).subscribe((res) => {
      location.reload();
      //this.spinner = false;
    });
  }
}
