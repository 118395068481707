import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  apiURL: string = "/api";

  constructor(private httpClient: HttpClient) {}

  public updateLanguage(langValue) {
    return this.httpClient.put(`${this.apiURL}/update-language`, langValue);
  }

  // public getProducts(url ?: string) {
  // 	return this.httpClient.get < Product[] > (`${this.apiURL}/products${url}`);
  // }
}
