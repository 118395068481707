import { Injectable } from "@angular/core";
import { SmartTableData } from "../data/smart-table";

@Injectable()
export class SmartTableService extends SmartTableData {
  data = [
    {
      id: 1,
      firstName: "Product 1",
      lastName: "Prdocut Desc 1",
      username: 10,
      email: 1,
      age: "Type 1",
    },
    {
      id: 2,
      firstName: "Product 2",
      lastName: "Prdocut Desc 2",
      username: 10,
      email: 2,
      age: "Type 2",
    },
    {
      id: 2,
      firstName: "Product 2",
      lastName: "Prdocut Desc 2",
      username: 10,
      email: 2,
      age: "Type 2",
    },
    {
      id: 3,
      firstName: "Product 3",
      lastName: "Prdocut Desc 3",
      username: 10,
      email: 3,
      age: "Type 3",
    },
    {
      id: 4,
      firstName: "Product 4",
      lastName: "Prdocut Desc 4",
      username: 10,
      email: 4,
      age: "Type 4",
    },
    {
      id: 5,
      firstName: "Product 5",
      lastName: "Prdocut Desc 5",
      username: 10,
      email: 5,
      age: "Type 5",
    },
    {
      id: 6,
      firstName: "Product 6",
      lastName: "Prdocut Desc 6",
      username: 10,
      email: 6,
      age: "Type 6",
    },
    {
      id: 7,
      firstName: "Product 7",
      lastName: "Prdocut Desc 7",
      username: 10,
      email: 7,
      age: "Type 7",
    },
    {
      id: 8,
      firstName: "Product 8",
      lastName: "Prdocut Desc 8",
      username: 10,
      email: 8,
      age: "Type 8",
    },
    {
      id: 9,
      firstName: "Product 9",
      lastName: "Prdocut Desc 9",
      username: 10,
      email: 9,
      age: "Type 9",
    },
    {
      id: 10,
      firstName: "Product 10",
      lastName: "Prdocut Desc 10",
      username: 10,
      email: 10,
      age: "Type 10",
    },
    {
      id: 11,
      firstName: "Product 11",
      lastName: "Prdocut Desc 11",
      username: 10,
      email: 11,
      age: "Type 11",
    },
    {
      id: 12,
      firstName: "Product 12",
      lastName: "Prdocut Desc 12",
      username: 10,
      email: 12,
      age: "Type 12",
    },
    {
      id: 13,
      firstName: "Product 13",
      lastName: "Prdocut Desc 13",
      username: 10,
      email: 13,
      age: "Type 13",
    },
    {
      id: 14,
      firstName: "Product 14",
      lastName: "Prdocut Desc 14",
      username: 10,
      email: 14,
      age: "Type 14",
    },
  ];

  getData() {
    return this.data;
  }
}
