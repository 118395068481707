import { Component, Input, OnInit } from "@angular/core";

import { Router } from "@angular/router";

import { NbMenuService, NbSidebarService } from "@nebular/theme";
import { UserData } from "../../../@core/data/users";
import { AnalyticsService } from "../../../@core/utils";
import { LayoutService } from "../../../@core/utils";
import { NbAuthService } from "@nebular/auth";
import { NB_WINDOW } from "@nebular/theme";
import { filter, map } from "rxjs/operators";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  @Input() position = "normal";

  user: any;

  userMenu = [{ title: "Log out" }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserData,
    private analyticsService: AnalyticsService,
    private layoutService: LayoutService,
    private authService: NbAuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.getToken().subscribe((value) => {
      let token: any;
      let values: any[];
      token = value["token"];
      values = token.split(",");
      this.user = { name: values[2], picture: "" };
    });

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === "my-context-menu"),
        map(({ item: { title } }) => title)
      )
      .subscribe((title) => {
        this.authService.logout("email").subscribe(
          (any) => this.router.navigate(["auth/login"]) // Your redirection goes here
        );
      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent("startSearch");
  }
}
