import { Injectable } from "@angular/core";
import { UserTableData } from "../data/user-table";

@Injectable()
export class UserTableService extends UserTableData {
  data = [
    {
      name: "User 1",
      picture: "Picture  1",
    },
    {
      name: "User 2",
      picture: "Picture  2",
    },
  ];

  getData() {
    return this.data;
  }
}
